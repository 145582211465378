.diagram {
    padding: 60px 0;
    @include breakpoint(lg) {
        padding: 45px 0;
    }
    @include breakpoint(md) {
        padding: 38px 0;
    }
    @include breakpoint(xs) {
      padding: 30px 0;
    }

    &-content{
        overflow:hidden;
        display: -ms-grid;
        -ms-grid-columns: 1fr;
        -ms-grid-rows: 1fr;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        position: relative;
        width: 100%;
        margin: 0 0 0 -200px;
        @include breakpoint(lg) {
          margin: 0;
        }

        .highlite-color.show-mobile{
            height:150px;
            font-weight: 700;
        }

        .single-frame{
            display: none;
            position: relative;
            -ms-grid-column: 1;
            -ms-grid-row: 1;
            grid-column: 1;
            grid-row: 1;
        }
    }
}
