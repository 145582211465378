#contact {
    @include breakpoint(lg) {
      margin-top: -100px;
    }

    @include breakpoint(md) {
      margin-top: -140px;
    }

    @include breakpoint(md) {
      margin-top: -190px;
    }
}

.cta {
    align-items: center;
    padding: 60px 0 200px;

    @include breakpoint(lg) {
      padding: 0 0 150px;
    }

    @include breakpoint(md) {
      padding: 0 0 100px;
    }

    @include breakpoint(md) {
      padding: 0 0 60px;
    }

  &-img {
    width: 100%;
    padding: 0 30px;

    @include breakpoint(md) {
      margin-bottom: 60px;
    }

    @include breakpoint(sm) {
        padding: 0;
    }
  }

  .info {
    &-title {
      color: #00697C;
      margin-bottom: 15px;
    }

    .contact {
      color: #CFDE40;
      display: flex;
      flex-direction: column;

      a {
        font-weight: bold;
      }
    }
  }
}
