.screen-slider {
    &-item {
        padding: 10px;

        img {
            box-shadow: 0 0 10px rgba(#00697C, 0.1);
        }
    }
}

.slick-arrow {
    align-items: center;
    background: transparent;
    border: 0;
    cursor: pointer;
    display: flex;
    font-size: 0px;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transform: translateX(-15%);
    width: 50%;
    z-index: 1;

    &:before {
        @extend %dataIcon;
        content: '\ea13';
        color: #00697C;
        font-size: 2rem;
        opacity: 1;
        transition: all .3s ease 0s;
        visibility: visible;
    }

    &:focus {
        outline: none;
    }

    &.slick-prev {
        &:before {
            content: '\ea12';
        }
    }

    &.slick-next {
        justify-content: flex-end;
        left: auto;
        right: 0;
        transform: translateX(15%);
    }
}

$slider-dots--size: 10px !default;

.slick-dots {
    align-items: center;
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 15px 0;
    padding: 0;
    width: 100%;

    & > li {
        align-items: center;
        display: flex;
    }

    button {
        background-color: #F5F5F5;
        border-radius: 50%;
        border: none;
        cursor: pointer;
        font-size: 0px;
        height: $slider-dots--size;
        margin: 0 10px;
        padding: 0;
        transition: all .3s ease 0s;
        width: $slider-dots--size;

        @include breakpoint(sm) {
            margin: 0 7px;
        }

        &:focus {
          outline: none;
        }
    }

    .slick-active button {
        background-color: #00697C;
    }
}