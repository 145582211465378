video{
    width:100%;
}
.video {
  padding: 60px 0;

  @include breakpoint(lg) {
    padding: 45px 0;
  }

  @include breakpoint(md) {
    padding: 40px 0;
  }

  &-title {
    margin-bottom: 35px;

    .headline2:before {
      content: "»";
      transform: translateX(-100%);
      position: absolute;
    }
  }

  &-container {
    display: flex;
  }

  &-content {
    ul {
      list-style-type: none;

      li {
        margin-bottom: 20px;
      }
    }
  }
  .order1{
      order:2;

      @include breakpoint(md) {
          order:1;
          margin-bottom: 60px;
      }

      @include breakpoint(sm) {
          margin-bottom: 40px;
      }
  }
  .order2{
      order:1;
      @include breakpoint(md) {
          order:2;
      }
  }
}
