.footer {
    padding: 60px 0;
    width: 100%;

    @include breakpoint(lg) {
        padding: 45px 0;
    }

    @include breakpoint(sm) {
        padding: 40px 0 60px;
    }

    &-nav {
        align-items: center;
        display: flex;
        justify-content: space-between;

        @include breakpoint(sm) {
            align-items: flex-start;
            flex-direction: column;
        }

        ul {
            align-items: center;
            display: inline-flex;
            list-style: none;

            @include breakpoint(lg) {
                justify-content: space-between;
                width: 100%;
            }

            @include breakpoint(sm) {
                display: flex;
                flex-basis: 100%;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: flex-start;
            }

            li {
                padding: 0 30px;


                @include breakpoint(sm) {
                    display: flex;
                    flex: 1;
                    flex-direction: column;
                    flex-basis: 100%;
                    max-width: 50%;
                }

                &:first-child {
                    @include breakpoint(lg) {
                        padding-left: 0;
                    }
                }

                &:last-child {
                    @include breakpoint(lg) {
                        padding-right: 0;
                    }

                    @include breakpoint(sm) {
                        margin: 15px 0;
                        max-width: unset;
                        padding: 0;
                    }
                }
            }
        }
    }

    a {
        color: #009BB7;
    }

    .logo-riwis {
        width: 230px;

        @include breakpoint(lg) {
            display: none;
        }

        @include breakpoint(sm) {
            display: block;
            margin-bottom: 20px;
            width: 130px;
        }
    }


    .logo-bg {
        margin-left: 200px;

        @include breakpoint(lg) {
            margin-left: 10%;
        }

        @include breakpoint(md) {
            margin-left: 30px;
        }

        a {
            align-items: center;
            display: flex;
            white-space: nowrap;
        }
        img {
            display: inline;
            filter: brightness(0) invert(1);
            margin-left: 30px;
            width: 200px;

            @include breakpoint(md) {
                width: 140px;
            }
        }
    }
}
