.stage{
    width: 100%;
    min-height: 56.25vw;
    overflow:hidden;
    display: -ms-grid;
    -ms-grid-columns: 1fr;
    -ms-grid-rows: 1fr;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    position: relative;
    margin-bottom:-2px;
    @include breakpoint(lg) {
        min-height: 75vw;
    }
    @include breakpoint(md) {
        min-height: 100vh;
    }
    @include breakpoint(sm) {
        display: flex;
        flex-direction: column;
    }

    .animation-container,
    .content-container{
        -ms-grid-column: 1;
        -ms-grid-row: 1;
        grid-column: 1;
        grid-row: 1;
        @include breakpoint(sm) {
            display: flex;
            min-height: 100vw;
        }
    }

    .animation-container{
        position: relative;
        width:41.5%;

        @include breakpoint(lg) {
            width: 53%;
        }

        @include breakpoint(md) {
            width: 85%;
        }

        * {
            transform: translate3d(0, 0, 0);
        }

        @include breakpoint(sm) {
            grid-row: 2;
            width:100%;
            order: 2;
            overflow:hidden;
            background-color: #F5F9FA;
        }

        .logo{
            opacity:0;
            align-self: flex-start;
            transition: opacity 1s ease-in-out 2s, visibility 1s ease-in-out 2s;
            left: 10vw;
            top:7vw;
            opacity: 0;
            z-index: 2;
            position: absolute;
            width: 33%;

            @include breakpoint(lg) {
                top: 80px;
                width: 35%;
            }

            @include breakpoint(sm) {
                left: 60px;
                transition: opacity 1s ease-in-out 0s, visibility 1s ease-in-out 0s;
                position: relative;
            }

            img{
                width: 100%;
                margin: 0 auto;
            }
        }

        .top{
            position: absolute;
            top:-100%;
            left:0px;
            width:100%;
            z-index: 1;
            //height:100%;
            transition: top 1.5s ease-in-out 0s;
            @include breakpoint(sm) {
                top:0;
                left:-100%;
                transition: left 1s ease-in-out 0s;
            }
        }
        .left{
            position: absolute;
            left: -7.4vw;
            top:50%;
            transform: translateY(-50%);
            width:100%;
            z-index: 3;
            opacity: 0;
            transition: opacity 1.5s ease-in-out 3s;
            @include breakpoint(lg) {
                left: -10.9vw;
                margin-top: -1px;
            }

            @include breakpoint(md) {
                left: -24.5vw;
            }

            @include breakpoint(sm) {
                left:0;
                transition: opacity 1s ease-in-out 2s;
            }
        }
        .bottom{
            position: absolute;
            left: 0px;
            bottom: -100%;
            margin-left: -1px;
            width: 100%;
            z-index: 2;
            //height:100%;
            transition: bottom 1.5s ease-in-out 1s;
            @include breakpoint(lg) {
                margin-left: 0;
            }
            @include breakpoint(sm) {
                transition: bottom 1s ease-in-out 1s;
            }
        }

        &.animate{
            .logo{
                opacity:1;
                visibility: visible;
            }
            .top{
                top:0px;
                @include breakpoint(sm) {
                    left:0;
                }
            }
            .left{
                opacity: 1;
            }
            .bottom{
                bottom: 0px;
                @include breakpoint(sm) {
                    left:0;
                }
            }
        }
    }

    .content-container{
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        flex-wrap: wrap;
        z-index: 10;
        padding: 30px 0;

        @include breakpoint(sm) {
            padding: 75px 0 0 0;
        }

        .logo{
            opacity: 0;
            width: 100%;
            transition: opacity 1.5s ease-in-out 0s;

            @include breakpoint(sm) {
                width: 42%;
            }

            img{
                max-width: 220px;
                min-width: 200px;
                margin: 0;

                @include breakpoint(sm) {
                    max-width: unset;
                    min-width: auto;
                }
            }
        }

        .top{
            align-self: flex-start;
            width: 100%;
            padding-top: 70px;
        }
        .center{
            align-self: center;
            width: 100%;
            @include breakpoint(sm) {
                margin-top: 0;
            }
        }

        h1{
            opacity: 0;
            transition: opacity 1s ease-in-out 2s;
            @include breakpoint(sm) {
                transition: opacity 1s ease-in-out 1s;
            }
        }
        p:not(.highlite-color){
            position: relative;
            opacity: 0;
            transition: left 1s ease-in-out 3s, opacity 1s ease-in-out 3s;

            @include breakpoint(sm) {
                left: -130%;
                transition: left 1s ease-in-out 2s, opacity 1s ease-in-out 2s;
            }
        }
        p.highlite-color{
            position: relative;
            opacity: 0;
            text-align: right;
            transition: all 1s ease-in-out 3s;
            @include breakpoint(sm) {
                transition: all 1s ease-in-out 3s;
            }
        }
        &.animate{
            .logo{
                opacity: 1;
            }
            h1{
                opacity: 1;
            }
            p{
                opacity: 1;
                left: 0px;
            }
        }
    }
}
