.banner {
  padding: 60px 0;
  width: 100%;
  color: #ffffff;

  @include breakpoint(lg) {
    padding: 45px 0;
  }

  @include breakpoint(md) {
    padding: 40px 0;
  }

  p {
    margin: 0;
  }

  &.icons {
      color: #ffffff;

      @include breakpoint(sm) {
        padding-bottom: 10px;
      }

      .row {
          align-items: flex-end;
          > div{
              @include breakpoint(md) {
                margin-bottom: 30px;
              }
          }
      }

      img {
          display: block;
          width: 90%;
          margin: 0 0 10px;

          @include breakpoint(lg) {
            width: 60px;
          }
      }
  }
}
