.service-nav {
    color: #00697C;
    font-size: 16px;
    font-weight: 700;
    height: 42px;
    opacity: 0;
    position: absolute;
    right: 120px;
    top: -100px;
    transition: top 1.5s ease-in-out 0s, opacity 1.5s ease-in-out 0s;
    z-index: 100;

    @include breakpoint(sm) {
        display: flex;
        background-color: #F5F9FA;
        height: auto;
        justify-content: space-between;
        right: 0;
        top: 0;
        transition: none;
        width: 100%;
    }

    &.is-expanded {
        @include breakpoint(sm) {
            box-shadow: 2px 2px 7px rgba(#00697C, 0.1);
        }

        .close-nav-btn {
            @include breakpoint(sm) {
                opacity: 1;
                transition: all .3s ease-in-out 0s;
                visibility: visible;
            }
        }
    }

    &.animate {
        top: 30px;
        opacity: 1;

        @include breakpoint(lg) {
            right: 70px;
            top: 15px;
        }

        @include breakpoint(sm) {
            right: 0;
            top: 0;
        }    
    }

    .login {
        align-items: center;
        display: inline-flex;
        height: 100%;
        margin: 0 15px;
        transition: all .3s ease-in-out 0s;

        @include breakpoint(lg) {
            margin: 0 10px;
        }

        @include breakpoint(sm) {
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            flex: 0 0 65%;
            margin: 0 15px;
            width: 65%;
        }

        &.is-visible {
            @include breakpoint(sm) {
                margin-bottom: 30px;
            }

            .login-btns{
                height: auto;
                opacity: 1;
                padding-left: 15px;
                transition: opacity .3s ease-in-out .1s, visibility .3s ease-in-out .1s;
                visibility: visible;
                width: auto;

                @include breakpoint(sm) {
                    padding: 0;
                }
            }
        }

        &-switch {
            font-weight: normal;

            @include breakpoint(sm) {
                align-items: center;
                display: flex;
                height: 42px;
            }

            &:hover {
                cursor: pointer;
            }
        }

        &-btns {
            height: 0;
            opacity: 0;
            padding-left: 0;
            transition: none;
            visibility: hidden;
            width: 0;

            @include breakpoint(sm) {
                white-space: nowrap;
            }
        }

        &-btn {
            background-color: #ffffff;
            box-shadow: 2px 2px 7px rgba(#00697C, 0.1);
            border-radius: 2px;
            display: inline-flex;
            margin: 0 5px;
            padding: 10px 20px;
            transition: all .3s ease-in-out 0s;

            &:hover, &:focus, &:active {
                border-color: #00697C;
            }

            &:first-child {
                @include breakpoint(sm) {
                    margin: 0;
                }
            }

            a {
                color: #00697C;
                display: flex;
            }

        }

        &-rk {
            &, & a {
                color: #000000;
            }
        }

        img {
            display: inline;
            margin-right: .5em;
            width: 1em;
        }
    }

    .language {
        align-items: center;
        display: inline-flex;
        height: 100%;
        font-weight: normal;
        list-style: none;
        margin: 0 15px;
        white-space: nowrap;

        @include breakpoint(lg) {
            margin: 0 10px;
        }

        @include breakpoint(sm) {
            height: 42px;
            margin: 0 15px;
        }

        li:first-child:after {
            content: "";
            background-color: #00697C;
            height: 12px;
            width: 2px;
            display: inline-block;
            margin: 0 10px;
        }

        li.active {
            font-weight: 700;
        }

        a {
            color: #00697C;
        }
    }

    .close-nav-btn {
        background-color: rgba(0, 105, 124, .2);
        bottom: 0;
        border-radius: 5px 5px 0 0;
        color: white;
        height: 15px;
        left: 50%;
        opacity: 0;
        position: absolute;
        text-align: center;
        transform: translateX(-50%);
        transition: none;
        visibility: hidden;
        width: 30px;

        img {
            width: 47%;
            margin: 0 auto;
        }
    }
}