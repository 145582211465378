@mixin breakpoint($point) {
  @if $point == xs {
    @media (max-width: 575.98px) { @content; }
  }
  @else if $point == sm {
    @media (max-width: 767.98px) { @content; }
  }
  @else if $point == md {
    @media (max-width: 991.98px) { @content; }
  }
  @else if $point == mdlg {
    @media (max-width: 1023.98px) { @content; }
  }
  @else if $point == lg {
    @media (max-width: 1199.98px) { @content; }
  }
  @else if $point == xl {
    @media (min-width: 1200px) { @content; }
  }
  @else if $point == xxl {
    @media (min-width: 2000px) { @content; }
  }
}

@font-face {
  font-family: "IconFont";
  src:url('/fonts/IconFont.eot');
  src:url('/fonts/IconFont.eot?#iefix') format('embedded-opentype'),
    url('/fonts/IconFont.woff') format('woff'),
    url('/fonts/IconFont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

// Placeholder selector dataIcon
// use: @extend %dataIcon;
%dataIcon {
  content: attr(data-icon);
  font-family: 'IconFont';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: inherit;
  top:0;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[data-icon]:before {
  @extend %dataIcon;
}

[data-icon-after]:after {
  @extend %dataIcon;
  content: attr(data-icon-after);
}


body {
    background: #fff;
    padding: 0;
    margin: 0;
    font-family: 'Maven Pro', sans-serif;
    font-size: 20px;
    line-height: 1.3;
    color: #052531;

    @include breakpoint(sm) {
        font-size: 18px;
    }
}

::selection{
    background-color: #CFDE40;
    color:#fff;
}

*{
    margin:0;
    padding:0;
    &:focus{
        outline:none;
    }
}

a{
    text-decoration: none;
}

img {
    width: 100%;
    display: block;
}

h1{
    font-size: 80px;
    line-height:1;
    font-weight: 700;
    margin: .67em 0 .33em;
    @include breakpoint(xxl) {
        font-size: 100px;
    }
    @include breakpoint(lg) {
        font-size: 65px;
    }
    @include breakpoint(md) {
        font-size: 50px;
    }
    @include breakpoint(sm) {
        font-size: 40px;
    }
}

h2, .headline2 {
    font-size: 35px;
    line-height:1.1;
    font-weight: 700;
    margin-bottom: .3em;

    @include breakpoint(lg) {
        font-size: 30px;
    }
    @include breakpoint(sm) {
        font-size: 24px;
    }
}

h3, .headline3, .subline {
    font-size: 30px;
    line-height: 1.2;
    font-weight: 700;
    @include breakpoint(lg) {
        font-size: 26px;
    }
    @include breakpoint(sm) {
        font-size: 22px;
    }
}

p {
    margin-bottom: 20px;
}

.subline {
    font-weight: normal;
}

.text-medium {
    font-size: 24px;

    @include breakpoint(lg) {
        font-size: 22px;
    }

    @include breakpoint(sm) {
        font-size: 18px;
    }
}

.is-bold {
    font-weight: 700;
}

.is-uppercase {
    text-transform: uppercase;
    letter-spacing: .05em;
}

.nowrap{
    white-space: nowrap;
}

.hide-md {
    display: block;
    @include breakpoint(md) {
        display: none;
    }
}

.show-md {
    display: none;
    @include breakpoint(md) {
        display: block;
    }    
}

.hide-mobile{
    display:block;
    @include breakpoint(sm) {
        display:none;
    }
}
.show-mobile{
    display:none;
    @include breakpoint(sm) {
        display:block;
    }
}

.text-white{
    color: #fff;
}

.text-petrol{
    color: #00697C;
}

.bg-special{
    background-color: #F5F9FA;
    @include breakpoint(sm) {
        background-color: #FFF;
    }
}
.bg-white{
    background: #fff;
}
.bg-primary{
    background: #097477;
}
.bg-banner {
  background-color: #CFDE40;
}
.bg-footer {
  background-color: #00697C;
  background-image: linear-gradient(to right, #00353E, #00697C);
}
.highlite-color,
a{
    color:#CFDE40;
}

.no-margin-bottom {
    margin-bottom: 0;
}

.no-uppercase {
    text-transform: none;
}

.container{
    @include breakpoint(sm) {
        padding: 0 30px;
    }
}

@import "service-nav";
@import "sticky-buttons";
@import "stage-animation";
@import "preview";
@import "banner";
@import "user-statements";
@import "diagram";
@import "video";
@import "screen-slider";
@import "demo";
@import "cta";
@import "footer";

@import "grid-overwrites";
