.sticky-buttons {
    position: fixed;
    z-index: 100;
    top: -300px;
    right: 0;
    width: 116px;
    height: 288px;
    background: url("../images/sticky.svg") no-repeat 0 0;
    background-size: cover;
    padding: 30px 30px 0;
    transition: top 1.5s ease-in-out 0s, opacity 1.5s ease-in-out 0s;
    @include breakpoint(sm) {
        transition: right 1s ease-in-out 2s, opacity 1s ease-in-out 2s;
    }

    &.animate{
        top: 0;
        opacity: 1;
        @include breakpoint(sm) {
            top: auto;
            right: 0;
        }
    }

    &-demo {
        img {
            transform: scale(1.2);
            margin: 0 auto 30px;
        }
    }

    &-phone {
        cursor: pointer;

        img {
            transform: scale(.8);
            margin: 0 auto;
        }

        &--number {
            position: relative;

            &-link {
                opacity: 0;
                position: absolute;
                white-space: nowrap;
                right: calc(100% + 24px);
                top: 50%;
                transform: translateX(100%) translateY(-50%);
                background-color: white;
                box-shadow: -5px 2px 7px rgba(0,105,124,0.1);
                color: #00697C;
                display: flex;
                align-items: center;
                padding: 15px 30px 15px 15px;
                transition: none;
                visibility: hidden;

                @include breakpoint(lg) {
                    right: calc(100% + 15px);
                    top: 30%;
                }   
            }

            &.is-expanded &-link {
                opacity: 1;
                transform: translateX(0%) translateY(-50%);
                transition: transform .3s ease 0s, opacity .3s ease .15s, visibility .3s ease .15s;
                visibility: visible;
            }
        }
    }

    @include breakpoint(lg) {
        padding: 30px 15px;
        width: 60px;
        height: 200px;
    }
    @include breakpoint(sm) {
        top: auto;
        bottom: -54px;
        right: -300px;
        transform: rotate(90deg) translateY(30%);
        height: 168px;
        width: 60px;
        padding: 30px 15px;

        img {
            transform: rotate(-90deg);
        }

        &-phone {
            img {
                transform: scale(-.8) rotate(90deg) scaleX(-1);
            }
        }

        &-demo {
            img {
                transform: scale(1.2) rotate(-90deg);
            }
        }
    }
}
