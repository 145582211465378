.preview{
    overflow:hidden;
    display: -ms-grid;
    -ms-grid-columns: 1fr;
    -ms-grid-rows: 1fr;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    position: relative;
    width:100%;
    position: sticky;
    top: 0;
    z-index: 1;

    @include breakpoint(md) {
        background-color: #00697C;
    }

    .slide{
        position: relative;
        -ms-grid-column: 1;
        -ms-grid-row: 1;
        grid-column: 1;
        grid-row: 1;
        left: -1px;
        transform: translate3d(0, 0, 0);
        @include breakpoint(lg) {
            height: 75vw;
            overflow: hidden;
        }
        @include breakpoint(md) {
            height: 100vh;
        }
        @include breakpoint(sm) {
            left: 0;
        }
        opacity:0;
        transition: opacity 1s ease-in-out 0s;

        &.visible{
            opacity:1;
            display:block;
        }

        &:first-child{
            transition: all 1.5s ease-in-out 1s;
            transform: translateX(-70%);

            @include breakpoint(lg) {
                transition: transform 1.5s ease-in-out 1s;
            }

            @include breakpoint(md) {
                transition: opacity 1.5s ease-in-out 1s;
            }
        }

        &-text {
            color: #00697C;
            position: absolute;
            text-align: right;
            top: 60px;

            @include breakpoint(lg) {
                color: #ffffff;
                text-align: left;
                top: 30px;
            }
            @include breakpoint(md) {
                background-color: #00697C;
                top: 60px;
            }
            @include breakpoint(xs) {
                top: 30px;
            }            
        }

        h2 {
            &.subline {
                bottom:60px;
                top:auto;

                @include breakpoint(lg) {
                    bottom: 45px;
                }
                @include breakpoint(md) {
                    bottom: 38px;
                }
                @include breakpoint(xs) {
                    bottom: 30px;
                }
            }

        }

        .text{
            position: absolute;
            left: 10vw;
            top: 60px;
            width:30%;
            color: #fff;

            @include breakpoint(lg) {
                color: #00697C;
                left: auto;
                right: 45px;
                top: 30px;
            }
            @include breakpoint(md) {
                color: #ffffff;
                left: 30px;
                right: auto;
                top: 250px;
                width: auto;
            }
            @include breakpoint(sm) {
                background-color: #00697C;
                left: auto;
                right: 30px;
                top: 200px;
            }
        }

        img {
            @include breakpoint(lg) {
                height: 75vw;
                margin-left: -1px;
                width: auto;
            }

            @include breakpoint(md) {
                height: auto;
                min-height: 100%;
                width: auto;
                min-width: 100%;
            }
        }

    }
    &.animate{
        .slide{
            &:first-child{
                opacity:1;
                transform: translateX(0%);
            }
        }
    }
}

.scrollspacer-preview {
    position: relative;
    width: 100%;
    height: 250vw;
    padding-bottom: 100vh;
    z-index: 0;
    @include breakpoint(sm) {
        height: 500vw;
    }
}
