.slider {
    position: sticky;
    top: 0;
    overflow: hidden;
    width: 100%;
    z-index: 1;

    @include breakpoint(lg) {
        max-height: 60vw;
    }

    @include breakpoint(md) {
        max-height: 90vh;
    }

    @include breakpoint(sm) {
        max-height: unset;
    }

    .inner{
        display: flex;
        position: relative;
        overflow-x: visible;
        min-width: 100%;
        flex-direction: row;
        transform: translate3d(0, 0, 0);
        will-change: right;

        @include breakpoint(lg) {
            max-height: 100%;
        }
    }

    section {
        display: flex;
        padding-left: 10vw;
        min-width: 60vw;
        position: relative;
        text-align: left;
        background-color: #fff;

        @include breakpoint(lg) {
            min-width: 80vw;
        }

        @include breakpoint(md) {
            min-width: 90vw;
        }
        @include breakpoint(sm) {
            flex-direction: column;
            min-width: 95vw;
            padding: 0;
        }

        &.-dark {
            //background-color: #00697C;
            background-image: linear-gradient(to right, #00697C, #00353E);
            color: #ffffff;

            // @include breakpoint(sm) {
            //     background-image: none;
            //     color: #000;
            // }
        }

        &:first-child {
            z-index: 4;

            @include breakpoint(md) {
                margin-right: 0;
            }

            & + section {
                z-index: 3;

                & + section {
                    z-index: 2;
                }
            }
        }

        &:last-child {
            min-width: 70vw;
            padding-right: 10vw;

            @include breakpoint(lg) {
                min-width: 90vw;
                padding-right: 5vw;
            }

            @include breakpoint(sm) {
                padding-right: 0;
            }
        }

        .slider-img {
            display: flex;
            align-items: flex-end;
            flex: 0 0 53%;
            min-width: 53%;
            order: 2;
            transform: translate(-5%);

            @include breakpoint(sm) {
                flex: auto;
                transform: translate(0px, -15vw);
            }

            img {
                margin: 0 auto;
                width: 80%;

                @include breakpoint(sm) {
                    width: 70vw;
                }
            }
        }

        .text {
            flex: 0 0 60%;
            margin: 100px 30px 50px 0;
            min-width: 60%;
            order: 1;

            @include breakpoint(sm) {
                margin: 0;
                order: 1;
                padding: 30px 30px 0;
            }

            p {
                margin-bottom: 45px;
                @include breakpoint(sm) {
                    margin-bottom: 30px;
                }

                &:last-child {
                    margin-bottom: 95px;
                    @include breakpoint(lg) {
                        margin-bottom: 70px;
                    }
                    @include breakpoint(md) {
                        margin-bottom: 30px;
                    }
                }

                &:nth-child(2) {
                    text-transform: uppercase;
                    font-weight: bold;
                    letter-spacing: 0.9px;
                    font-size: 45px;
                    @include breakpoint(md) {
                        font-size: 30px;
                    }
                    @include breakpoint(sm) {
                        font-size: 24px;
                    }
                }
            }
        }
    }
}

.scrollspacer {
    position: relative;
    width: 100%;
    height: 190vw;
    z-index: 0;

    @include breakpoint(sm) {
        height: 300vw;
    }
}
