.demo-background {
    margin-bottom: -15vmax;
    @include breakpoint(xl) {
        margin-bottom: -10vmax;
    }
    @include breakpoint(lg) {
        margin-bottom: 0;
    }
}

.demo {
    position: relative;
    display: -ms-grid;
    -ms-grid-columns: 1fr;
    -ms-grid-rows: 1fr;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    position: relative;
    width: 100%;

    &-background {
        @include breakpoint(sm) {
            overflow: hidden;
        }
    }

    .bg,
    .content {
        -ms-grid-column: 1;
        -ms-grid-row: 1;
        grid-column: 1;
        grid-row: 1;
    }
    .bg{
        overflow: hidden;
        img{
            min-width: 1800px;

            @include breakpoint(lg) {
                min-width: 140%;
            }

            @include breakpoint(md) {
                min-width: 165%;
            }

            @include breakpoint(sm) {
                min-width: 415%;
                margin-left: -45%;
            }
        }
    }
    .content {
        padding: 100px 0 60px;

        @include breakpoint(lg) {
            padding: 60px 0 60px;
        }

        @include breakpoint(sm) {
            padding: 40px 0 60px;
        }

        img{
            transform: translateX(-4%) translateY(-40%);

            @include breakpoint(lg) {
                margin-top: -110px;
                margin-left: 60px;
                padding: 0 0 60px;
                transform: none;
            }
            @include breakpoint(md) {
                margin-top: -35px;
                margin-left: 50px;
            }

            @include breakpoint(sm) {
                margin: 0;
                padding: 0;
                transform: translateX(-5%) translateY(24%);
                width: 130%;
            }
        }
    }

    h3 {
        font-size: 35px;
        line-height: 40px;
        @include breakpoint(sm) {
            font-size: 26px;
            line-height: 36px;
        }
    }
    a{
        display: block;
        margin-top:15px;

    }
}
